// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.75;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
    text-decoration: none;
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}

.page-section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
  }
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

// colori tag progetti
.ColorHesploraGold{
  background-color: $HesploraGold;
  font-weight: normal;
}
.ColorHesploraGreen{
  background-color: $HesploraGreen;
  font-weight: normal;
}
.ColorHesploraRed{
  background-color: $HesploraRed;
  font-weight: normal;
}

.bg-grigio-hesplora{
  background-color: $gray-200;
}

.colore-hesplora{
  color: $primary;
}

.slogan{
  background-color: $gray-100;
  padding-top: 2em;
  padding-bottom: 2em;
}

.hvr-tag {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-tag:hover, .hvr-tag:focus, .hvr-tag:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.margini-customizzati{ //a destra sono maggiori rispetto a sinistra per allineare i margini alla navbar
  padding-left: 4.2rem; 
  padding-right: 5rem;
}
@media (max-width: 991px) { //adattamento margini per visualizzazione schermi piccoli. È stato scelto 991px perché coincide con l'apparizione del tasto menù al posto dei titoli nella navbar
  .margini-customizzati{
    padding-left: 3rem; 
    padding-right: 3rem;
  }
}

@media (max-width: 425px) {  /* con schermo <=425pxm aumentiamo il padding-top */
  .adattamento_smartphone {
    padding-top: 50px;
  }
}

.immagine_gold_green_red{
  width: 100vw; 
  min-height: 150px; 
  object-fit: cover; 
  display: block;
}

.margini-navbar{
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 425px){
  .margini-navbar{
    padding: 0;
  }
}

.dropdown-center{
  left: 50%;
  right: auto; 
  width: 48px; 
  text-align: center;
  transform: translate(-50%, 0);
}