#container {
    height: 100px;
    position: relative;
    overflow: hidden;
}

.photobanner {
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    white-space: nowrap;
    animation: bannermove 180s linear infinite;
}

.photobanner img {
    margin: 0 2em
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-50%, 0);
    }
}