// Styling for the masthead
header.masthead {
  text-align: center;
  height: 100vh; // altezza dinamica in base allo schermo
  width: 100%;
  position: relative;
  .intro-text {
    padding-top: auto;
    padding-bottom: auto;
    .intro-lead-in {
      color: $dark_grey;
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 25px;
    }
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
}

.carousel-item img {
  width: 100vw; // larghezza massima del viewport
  height: 100vh; // altezza massima del viewport
  object-fit: cover; // mantiene l'intera immagine visibile
}
#carouselExampleFade {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.carosello_header {
  transition: opacity 2s ease-in-out !important; // Imposta una transizione più lenta
}
@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      padding-top: 200px;
      padding-bottom: 200px;
      .intro-lead-in {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 25px;
      }
      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }
  }
}

.dropdown-menu {
    min-width: auto;
}
.dropdown-item {
  padding: 5px 10px;
  white-space: nowrap;
}