// Styling for the progetti section
#progetti {
  .progetti-item {
    right: 0;
    margin: 0 0 15px;
    .progetti-link {
      position: relative;
      display: block;
      max-width: 400px;
      margin: 0 auto;
      cursor: pointer;
      .progetti-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        background: fade-out($primary, 0.1);
        &:hover {
          opacity: 1;
        }
        .progetti-hover-content {
          font-size: 20px;
          position: absolute;
          top: 50%;
          width: 100%;
          height: 20px;
          margin-top: -12px;
          text-align: center;
          color: white;
          i {
            margin-top: -12px;
          }
          h3,
          h4 {
            margin: 0;
          }
        }
      }
    }
    .progetti-caption {
      max-width: 400px;
      margin: 0 auto;
      padding: 25px;
      background-color: $white;
      h4 {
        margin: 0;
        text-transform: none;
      }
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }
  * {
    z-index: 2;
  }
}
@media (min-width: 767px) {
  #progetti {
    .progetti-item {
      margin: 0 0 30px;
    }
  }
}

.progetti-modal {
  .modal-dialog {
    align-items: center;
    margin-top: 61px;
    max-width: 65vw;
  }
  @media (max-width: 1000px) {
    .modal-dialog {
      margin-top: 1rem;
      max-width: 95vw;
    }
  }
  .modal-content {
    padding: 43px 0;
    text-align: center;
    h2 {
      font-size: 2em;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 10px;
    }
    p.item-intro {
      font-size: 16px;
      font-style: italic;
      margin: 20px 0 30px;
    }
    ul.list-inline {
      margin-top: 0;
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 30px;
    }
    button {
      cursor: pointer;
    }
  }
  .close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    &:hover {
      opacity: 0.3;
    }
    .lr {
      /* Safari and Chrome */
      z-index: 1051;
      width: 1px;
      height: 30px;
      margin-left: 35px;
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: $gray-900;
      .rl {
        /* Safari and Chrome */
        z-index: 1052;
        width: 1px;
        height: 30px;
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        background-color: $gray-900;
      }
    }
  }
}


.holographic-card {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.holographic-card img {
  position: relative;
  z-index: 2;
}

.holographic-card:hover {
  transform: scale(1.03);
  box-shadow: 0 0 5px 5px rgba(236, 124, 28, 0.75);
}


.hvr-reveal2{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  padding: 4px;
}

.hvr-reveal2:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-color: $primary;
  border-style: solid;
  border-width: 0;
  -webkit-transition-property: border-width;
  transition-property: border-width;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-reveal2:hover:before, .hvr-reveal2:focus:before, .hvr-reveal2:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-width: 4px;
}

.hvr-reveal2:hover{
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);

  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition:transform 0.5s ease-in-out;
  -ms-transition:transform 0.5s ease-in-out;
}

.immagini_progetti_modal{
  display: block;
  margin-left: auto;
  margin-right: auto;
}